import React from "react";
import Header from "../component/Header";
import Footer from "../component/Footer";
import { Helmet } from "react-helmet";
import SiteLink from "../component/icons/SiteLink";

export default function PortfolioBodyArt() {

    return (
        <>
            <Helmet>
                <title>Body Art Website Development | Custom Fitness Booking Platform | aBox Agency</title>
                <meta name="title" content="Body Art Website Development | Custom Fitness Booking Platform | aBox Agency" />
                <meta name="description" content="aBox Agency developed a custom booking platform for Body Art, offering seamless service enrollments, combo bookings, and advanced fitness solutions through React JS development and Figma design. Explore our work now." />
                <meta name="keywords" content="aBox Agency, Body Art, fitness platform, custom booking solutions, React JS development, Figma design, service enrollments, combo booking system, seamless user experience, fitness technology, electrostimulation workouts, cellulite massage, targeted fat loss, fitness website development, online booking, user-centric design, advanced integrations, fitness brand Romania, digital fitness solutions, booking system, user interaction, web development, custom solutions, aBox expertise, fitness web design, fitness app development, Romania fitness brand, service platform, digital transformation, UX/UI design, Shopify expert, Shopify development, Shopify design, Shopify store setup, Shopify website design, Shopify customization, Shopify theme development, Shopify SEO services, Shopify app integration, Shopify eCommerce solutions, Shopify maintenance, Shopify migration services, Shopify optimization, Shopify consulting, Shopify user experience, Shopify performance enhancement, Shopify analytics, Shopify marketing strategies, Shopify store management, Shopify product management, Shopify payment integration, Shopify sales optimization, Shopify training, Shopify support, Shopify hosting services, Shopify checkout optimization, Shopify inventory management, Shopify store redesign, Shopify automation, Shopify branding" />
                <link rel="canonical" href="https://www.abox.agency/portfolio/bodyart/" />
            </Helmet>

            <Header />

            <div className="page_bg bg_bodyart">
                <div className="header_padding">
                    {/* hero section */}
                    <div className="section_detail">
                        <div className="container-fluid p-0">
                            <div className="d-xl-flex">
                                <div className="product_space">
                                    <p className="product_name bodyart_text2_bg dl_text2_bg westerville_text">Body Art</p>
                                </div>
                                <div className="w-100 line_h_0">
                                    {/* <video width={"100%"} autoPlay loop muted playsInline>
                                        <source src="https://d1cihhbb125h48.cloudfront.net/1724409792.mp4" type="video/mp4" />
                                    </video> */}
                                    <img src="/assets/image/clipchamp.webp" className="w-100" alt="clipchamp" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* detail section */}
                <div className="section_space">
                    <div className="container">
                        <div className="div-block-40 flex-column">
                            <div className="row">
                                <div className="col-xxl-7 col-lg-8 col-md-10">
                                    <p className="product_name bodyart_text2_bg writing_mod westerville_text">Published in 2024</p>
                                    <h1 className="services_title bodyart_text mb-0">
                                        Body Art <br />
                                        <span className="bodyart_title">
                                            Easy Fitness Custom <br className="d-sm-block d-none"/> Booking Solutions
                                        </span>
                                    </h1>
                                </div>
                                <div className="vr_border"></div>
                                <div className="col-xl-7 pad_right">
                                    <p className="services_content bodyart_text mb-3">At aBox, we designed and developed Body Art's website using Figma and React JS. We focused on delivering seamless booking services, service enrollments, and integrations that cater to the brand’s fitness solutions.</p>
                                    <p className="services_content bodyart_text">The brand had complex requirements, including multiple combo bookings tied to calendar availability. We implemented a custom solution to provide a user-friendly experience while keeping everything up-to-date for easy interaction.</p>
                                    <ul className="official_web">
                                        <li>
                                            <a href="https://bodyart-ems.ro/" target="_blank" className="d-flex align-items-center link_btn bodyart_text2_bg westerville_text">Official Website
                                                <SiteLink />
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                                <div className="col-xl-5">
                                    <div className="row">
                                        <div className="col-md-6">
                                            <p className="bodyart_title services_menu">Services</p>
                                        </div>
                                        <div className="col-md-6">
                                            <ul className="services_menu_list bodyart_list">
                                                <li>React Js</li>
                                                <li>Web Development</li>
                                                <li>UIUX Design</li>
                                                <li>Onsite SEO</li>
                                                <li>Integration</li>
                                            </ul>
                                        </div>
                                        <div className="menu_list_border"></div>
                                        <div className="col-md-6">
                                            <p className="bodyart_title services_menu">Industries</p>
                                        </div>
                                        <div className="col-md-6">
                                            <ul className="services_menu_list bodyart_list">
                                                <li>Fitness Training</li>
                                                <li>Wellness Booking Platform</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Logo section */}
                <div className="section_space">
                    <div className="container">
                        <div className="div-block-40 flex-column">
                            <div className="bg_banner d-flex justify-content-center align-items-center bg_bodyart_logo">
                                <img src="/assets/image/portfolios/bodyart/logo.svg" className="banner_logo" alt="Logo" />
                            </div>
                        </div>
                    </div>
                </div>

                {/* Product section */}
                <div className="section_space">
                    <div className="container">
                        <div className="div-block-40 flex-column">
                            <div>
                                <img src="/assets/image/portfolios/bodyart/bodyart1.jpg" className="w-100" alt="BodyArt" />
                            </div>
                        </div>
                    </div>
                </div>

                {/* content Section */}
                <div className="section_space">
                    <div className="container">
                        <div className="div-block-40 flex-column">
                            <div className="row justify-content-center">
                                <div className="col-xxl-6 col-xl-7 col-lg-8 col-md-10">
                                    <h2 className="brand_title bodyart_title">Streamlining Complex Bookings with Advanced Solutions</h2>
                                    <p className="services_pera bodyart_text font_regular mb-0">
                                        We tackled Body Art's booking challenges by developing a flexible combo booking system. Our solution aligns with calendar availability, allowing users to schedule multiple services effortlessly.
                                    </p>
                                </div>
                            </div>
                            <div className="strenth_bg gaps_sweetly p-0 strenth_my_80 mb-0 bg_transparent">
                                <div className="gaps gap_20 gaps_columns">
                                    <img src="/assets/image/portfolios/bodyart/bodyart2.jpg"
                                        alt="BodyArt" className="img-fluid w-100" />
                                    <div>
                                        <img src="/assets/image/portfolios/bodyart/bodyart3.jpg"
                                            alt="BodyArt" className="img-fluid w-100" />
                                    </div>
                                </div>
                            </div>
                            <div className="strenth_bg p-0 gaps_sweetly bg_transparent mt_20">
                                <div className="gaps gaps_columns gap_20">
                                    <img src="/assets/image/portfolios/bodyart/bodyart4.jpg"
                                        alt="BodyArt" className="img-fluid w-100" />
                                </div>
                                <div className="gaps sweet_delect p-0 mt_20 bg-transparent gap_20">
                                    <img src="/assets/image/portfolios/bodyart/bodyart5.jpg"
                                        alt="BodyArt" className="img-fluid w-100" />
                                    <img src="/assets/image/portfolios/bodyart/bodyart6.jpg"
                                        alt="BodyArt" className="img-fluid w-100" />
                                </div>
                                <div className="mt_20">
                                    <img src="/assets/image/portfolios/bodyart/bodyart7.jpg"
                                        alt="BodyArt" className="img-fluid w-100" />
                                </div>
                                <div className="mt_20">
                                    <img src="/assets/image/portfolios/bodyart/bodyart8.jpg"
                                        alt="BodyArt" className="img-fluid w-100" />
                                </div>
                                <div className="mt_20">
                                    <img src="/assets/image/portfolios/bodyart/bodyart9.jpg"
                                        alt="BodyArt" className="img-fluid w-100" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Digital brand section */}
                <div className="section_space">
                    <div className="container">
                        <div className="div-block-40 flex-column">
                            <div className="row">
                                <div className="col-xxl-6 col-lg-7 col-md-8">
                                    <h2 className="brand_title bodyart_title">Improving Experience with Flawless Service Integration</h2>
                                </div>
                                <div className="col-12">
                                    <p className="services_pera bodyart_text mb-0">
                                        The new platform now offers seamless service enrollments and a smooth booking experience, driving user satisfaction and engagement. Explore the live site at  <a href="https://bodyart-ems.ro/" target="_blank" className="official_link bodyart_text">www.bodyart-ems.ro</a>
                                    </p>
                                </div>
                            </div>
                            <div className="strenth_bg strenth_my_80 mb-0 p-0 bg_transparent">
                                <div className="gaps gaps_columns">
                                    <img src="/assets/image/portfolios/bodyart/bodyart10.jpg"
                                        alt="BodyArt" className="img-fluid w-100" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="section_space pt-0">
                    <div className="container">
                        <div className="div-block-40 flex-column">
                            <div className="strenth_bg strenth_my_80 p-0 bg_transparent">
                                <div className="gaps sweet_delect p-0 bg-transparent gap_20 mt-0">
                                    <img src="/assets/image/portfolios/bodyart/bodyart11.jpg"
                                        alt="BodyArt" className="img-fluid w-100" />
                                    <img src="/assets/image/portfolios/bodyart/bodyart12.jpg"
                                        alt="BodyArt" className="img-fluid w-100" />
                                    <img src="/assets/image/portfolios/bodyart/bodyart13.jpg"
                                        alt="BodyArt" className="img-fluid w-100" />
                                    <img src="/assets/image/portfolios/bodyart/bodyart14.jpg"
                                        alt="BodyArt" className="img-fluid w-100" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Color Palette section */}
                <div className="container">
                    <div className="div-block-40 flex-column">
                        <h3 className="palette_title bodyart_title">Color Palette</h3>
                        <div>
                            <img src="/assets/image/portfolios/bodyart/bodyart15.jpg" className="w-100" alt="BodyArt color palette" />
                        </div>
                    </div>
                </div>

                {/* Playfair section */}
                <div className="section_padd">
                    <div className="container">
                        <div className="div-block-40 flex-column">
                            <div className="row">
                                <div className="col-12">
                                    <img src="/assets/image/portfolios/bodyart/bodyart16.jpg" alt="BodyArt font" className="w-100" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Testimonial section */}
                <div class="perform_space py-5">
                    <div class="container">
                        <div class="div-block-40 flex-column"></div>
                    </div>
                </div>

                {/* other project */}
                <div className="next_project_bg mia_bg">
                    <div className="container">
                        <div className="text-center">
                            {/* <p className="next_button font_regular">Scroll Down to Next</p> */}
                            <a href="/portfolio/mia" className="next_button font_regular">Next</a>
                        </div>
                    </div>
                </div>
            </div>

            <Footer />
        </>
    );
}