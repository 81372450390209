import React from "react";
import Header from "../component/Header";
import Footer from "../component/Footer";
import { Helmet } from "react-helmet";
import SiteLink from "../component/icons/SiteLink";

export default function PortfolioDLFilms() {

    return (
        <>
            <Helmet>
                <title>DL Films Portfolio | Photography Studio Showcase | aBox Agency</title>
                <meta name="title" content="DL Films Portfolio | Photography Studio Showcase | aBox Agency" />
                <meta name="description" content="Explore DL Films' captivating photography and cinematic work in our portfolio. Witness the artistry of this photography studio, beautifully showcased by aBox Agency. Discover the magic of visual storytelling through their lens." />
                <meta name="keywords" content="Box, Shopify, E-commerce, Web Agency, Web Development, Shopify store, A/B, Our Clients, Client Success Stories, UIUX, Passionate Community, DL Films, Photography Studio, Portfolio Showcase, aBox Agency, Cinematic Photography, Visual Storytelling, Creative Photography, Photography Showcase, Artistic Imagery, Professional Photography, Web Development Services, Website Development, Web Design Showcase, Branding Solutions, Web Agency Portfolio, UI/UX Design, Web Development Expertise, Professional Web Design, Ecommerce Success, Website Branding, Shopify Web Solutions, Ecommerce Development, UI/UX, Web Agency Services, Web Design Excellence" />
                <link rel="canonical" href="https://www.abox.agency/portfolio/dl-films/" />
            </Helmet>

            <Header />

            <div className="page_bg bg-dark">
                <div className="header_padding">
                    {/* hero section */}
                    <div className="section_detail">
                        <div className="container-fluid p-0">
                            <div className="d-xl-flex">
                                <div className="product_space">
                                    <p className="product_name dl_text2_bg text_light">dlfilms</p>
                                </div>
                                <div className="w-100 line_h_0">
                                    <video width={"100%"} autoPlay loop muted playsInline>
                                        <source src="https://d1cihhbb125h48.cloudfront.net/1697525288.mp4" type="video/mp4" />
                                    </video>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* detail section */}
                <div div className="section_space">
                    <div className="container">
                        <div className="div-block-40 flex-column">
                            <div className="row">
                                <div className="col-xl-8 col-lg-9 col-md-10">
                                    <p className="product_name dl_text2_bg writing_mod text_light">Published in 2023</p>
                                    <h1 className="services_title text_light mb-0">
                                        Capturing Unforgettable <br />
                                        <span className="text_light">
                                            Moments Through <br />
                                            the Lens
                                        </span>
                                    </h1>
                                </div>
                                <div className="vr_border"></div>
                                <div className="col-xl-7 pad_right">
                                    <p className="services_content text_light">This brand is a creative photography studio dedicated to capturing moments through their exceptional photography art. They looked to aBox's experience in order to showcase their work and create an online presence. They received an excellent portfolio shopify website from aBox, which recognized their special requirements and skillfully showcased their artistic work and talent.</p>
                                    <ul className="official_web">
                                        <li>
                                            <a href="https://dlfilms.in/" target="_blank" className="d-flex align-items-center link_btn dl_link">Official Website
                                                <SiteLink />
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                                <div className="col-xl-5">
                                    <div className="row">
                                        <div className="col-md-6">
                                            <p className="text_shiska services_menu">Services</p>
                                        </div>
                                        <div className="col-md-6">
                                            <ul className="services_menu_list services_list">
                                                <li>Website</li>
                                                <li>UI/UX</li>
                                                <li>Visual Identity</li>
                                                <li>Web Design</li>
                                                <li>Web Development</li>
                                                <li>Onsite SEO</li>
                                                <li>A/B Testing</li>
                                            </ul>
                                        </div>
                                        <div className="menu_list_border"></div>
                                        <div className="col-md-6">
                                            <p className="text_shiska services_menu">Industries</p>
                                        </div>
                                        <div className="col-md-6">
                                            <ul className="services_menu_list services_list">
                                                <li>Photography and Artistry</li>
                                                <li>Portfolio</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Logo section */}
                <div div className="section_space">
                    <div className="container">
                        <div className="div-block-40 flex-column">
                            <div className="bg_banner d-flex justify-content-center align-items-center bg_dlfilms">
                                <img src="/assets/image/portfolios/dlfilm/logo.svg" className="banner_logo" alt="Logo" />
                            </div>
                        </div>
                    </div>
                </div>

                {/* Product section */}
                <div div className="section_space">
                    <div className="container">
                        <div className="div-block-40 flex-column">
                            <div>
                                <img src="/assets/image/portfolios/dlfilm/dlfilm2.webp" className="w-100" alt="DL Films" />
                            </div>
                        </div>
                    </div>
                </div>

                {/* content Section */}
                <div div className="section_space">
                    <div className="container">
                        <div className="div-block-40 flex-column">
                            <div className="row justify-content-center">
                                <div className="col-xxl-6 col-xl-7 col-lg-8 col-md-10">
                                    <h2 className="brand_title text_light">Framing Your Special Memories</h2>
                                    <p className="services_pera text_light font_regular mb-0">
                                        Our team of tech-savvy experts worked their magic to bring DL Films' unique vision to life. We've tailored our website to ensure that every click is a breeze. Our user-friendly interface showcases our diverse portfolio of pre-wedding and family event photography, making it easy for you to explore our work.
                                    </p>
                                </div>
                            </div>
                            <div className="strenth_bg strenth_my_80 mb-0 px-0 pt-0 gaps_sweetly bg_transparent">
                                <div className="gaps">
                                    <div>
                                        <img src="/assets/image/portfolios/dlfilm/dlfilm3.webp"
                                            alt="DL Films" className="img-fluid w-100" />
                                    </div>
                                    <div className="d-flex justify-content-between flex-column gap_10">
                                        <img src="/assets/image/portfolios/dlfilm/dlfilm4.webp"
                                            alt="DL Films" className="img-fluid w-100" />
                                        <img src="/assets/image/portfolios/dlfilm/dlfilm5.webp"
                                            alt="DL Films" className="img-fluid w-100" />
                                        <img src="/assets/image/portfolios/dlfilm/dlfilm6.webp"
                                            alt="DL Films" className="img-fluid w-100" />
                                    </div>
                                </div>
                            </div>
                            <div>
                                <img src="/assets/image/portfolios/dlfilm/dlfilm7.webp"
                                    alt="DL Films" className="img-fluid w-100" />
                            </div>
                            <div className="strenth_bg pb-0 sweet_delect bg_dlfilms">
                                <div className="gaps">
                                    <img src="/assets/image/portfolios/dlfilm/dlfilm8.webp"
                                        alt="DL Films" className="img-fluid w-100" />
                                    <img src="/assets/image/portfolios/dlfilm/dlfilm9.webp"
                                        alt="DL Films" className="img-fluid w-100" />
                                    <img src="/assets/image/portfolios/dlfilm/dlfilm10.webp"
                                        alt="DL Films" className="img-fluid w-100" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Digital brand section */}
                <div div className="section_space">
                    <div className="container">
                        <div className="div-block-40 flex-column">
                            <div className="row">
                                <div className="col-xxl-6 col-lg-7 col-md-8">
                                    <h2 className="brand_title text_light">Where Tradition Meets the Snap of Today</h2>
                                </div>
                                <div className="col-12">
                                    <p className="services_pera text_light mb-0">
                                        DL Films takes you on a journey where tradition dances hand in hand with the modern click. Whether you're seeking pre-wedding enchantment or family event festivities, we've got you covered. Dive into the world of timeless photography at <a href="https://dlfilms.in/" target="_blank" className="text_light services_link">www.dlfilms.in</a>, where every photograph is a story waiting to be told.</p>
                                </div>
                            </div>
                            <div className="strenth_bg strenth_my_80 p-0 bg_transparent">
                                <div className="gaps">
                                    <img src="/assets/image/portfolios/dlfilm/dlfilm11.webp"
                                        alt="DL Films" className="img-fluid w-100" />
                                    <img src="/assets/image/portfolios/dlfilm/dlfilm12.webp"
                                        alt="DL Films" className="img-fluid w-100" />
                                    <img src="/assets/image/portfolios/dlfilm/dlfilm13.webp"
                                        alt="DL Films" className="img-fluid w-100" />
                                    <img src="/assets/image/portfolios/dlfilm/dlfilm14.webp"
                                        alt="DL Films" className="img-fluid w-100" />
                                </div>
                            </div>
                            <div className="strenth_bg p-0 bg_transparent">
                                <img src="/assets/image/portfolios/dlfilm/dlfilm15.webp"
                                    alt="DL Films" className="img-fluid w-100" />
                            </div>
                        </div>
                    </div>
                </div>

                {/* Color Palette section */}
                {/* <div div className="section_padd" >
                    <div className="container">
                        <div className="div-block-40 flex-column">
                            <h3 className="palette_title text-white">Color Palette</h3>
                            <div>
                                <img src="/assets/image/portfolios/sportsaber/sportsaber15.webp" className="w-100" alt="DL Films" />
                            </div>
                        </div>
                    </div>
                </div> */}

                {/* Playfair section */}
                <div div className="section_padd" >
                    <div className="container">
                        <div className="div-block-40 flex-column">
                            <div className="row">
                                <div className="col-12">
                                    <img src="/assets/image/portfolios/dlfilm/dlfilm16.webp" alt="DL Films font" className="w-100" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Testimonial section */}
                <div className="perform_space">
                    <div className="container">
                        <div className="div-block-40 flex-column">
                            <div className="row justify-content-center">
                                <div className="col-lg-9 col-md-10">
                                    <p className="text_light perform_pera">These Guys transformed our photography website into a stunning masterpiece. Their web development and design services brought our family events and marriage photo studio to life. We're thrilled with the results! They truly understand our vision and delivered beyond our expectations. Abox Agency is the go-to choice for anyone seeking a professional and creative web solution. </p>
                                    <h3 className="title_color padd_s text_shiska">Mr. Vishal Chodvadia</h3>
                                    <p className="font_medium services_pera text_light mb-0 padd_s">Founder of DL Films</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* other project */}
                <div div className="next_project_bg ubar_bg">
                    <div className="container">
                        <div className="text-center">
                            {/* <p className="next_button font_regular">Scroll Down to Next</p> */}
                            <a href="/portfolio/ubar" className="next_button font_regular">Next</a>
                        </div>
                    </div>
                </div>
            </div>

            <Footer />
        </>
    );
}