import React from "react";
import { Helmet } from "react-helmet";
import Header from "../../component/Header";
import Footer from "../../component/Footer";
import LetsTalk from "../../component/LetsTalk";

export default function LabelRareSuga() {

      return (
            <>
                  <Helmet>
                        <title></title>
                        <meta name="title" content="" />
                        <meta name="description" content="" />
                        <meta name="keywords" content="" />
                        <link rel="canonical" href="" />
                  </Helmet>

                  <Header />

                  <div className="bg-white">
                        {/* hero section */}
                        <div className="hero_padding section_detail">
                              <div className="container">
                                    <div className="row">
                                          <div className="col-xxl-6 col-lg-8 col-md-10">
                                                <p className="product_name writing_mod teatox_text teatox_text_bg">3D</p>
                                                <h1 className="services_title ras_title mb-0"><span>Rare Suga</span> <br /> <span className="text_black">Nature's Essence, <br /> Elevated in 3D</span></h1>
                                          </div>
                                    </div>
                              </div>
                        </div>

                        {/* Product Section */}
                        <div className="product_banner">
                              <img src="/assets/image/label/rare_suga/raresuga1.jpg"
                                    alt="Rare Suga" className="img-fluid w-100" />
                        </div>

                        {/* detail section */}
                        <div className="ui_section">
                              <div className="container">
                                    <div className="row justify-content-between">
                                          {/* <div className="vr_border"></div> */}
                                          <div className="col-xxl-5 col-xl-6 pad_right">
                                                <p className="services_content text_black">For RAS Luxury Oils, aBox Agency delivered stunning 3D renders and intricate product modeling. Our work highlights RAS's dedication to natural luxury, capturing the purity and essence of their botanical ingredients through striking visuals.</p>
                                          </div>
                                          <div className="col-xxl-6 col-xl-5">
                                                <div className="row">
                                                      <div className="col-md-6">
                                                            <p className="text_black services_menu">Services</p>
                                                      </div>
                                                      <div className="col-md-6">
                                                            <ul className="services_menu_list ui_service_list">
                                                                  <li>3D Render</li>
                                                                  <li>3D Modeling</li>
                                                                  <li>Product Visualization</li>
                                                            </ul>
                                                      </div>
                                                      <div className="ui_list_border"></div>
                                                      <div className="col-md-6">
                                                            <p className="text_black services_menu">Industries</p>
                                                      </div>
                                                      <div className="col-md-6">
                                                            <ul className="services_menu_list ui_service_list">
                                                                  <li>Beauty and Skincare</li>
                                                                  <li>E-Commerce</li>
                                                            </ul>
                                                      </div>
                                                </div>
                                          </div>
                                    </div>
                              </div>
                        </div>

                        {/* view section */}
                        <div className="ui_section">
                              <div className="container">
                                    <div className="row mt_30 gap-md-0 gap-4">
                                          <div className="col-md-6">
                                                <div>
                                                      <img src="/assets/image/label/rare_suga/raresuga2.jpg" className="w-100" alt="Rare Suga" />
                                                </div>
                                          </div>
                                          <div className="col-md-6">
                                                <div>
                                                      <img src="/assets/image/label/rare_suga/raresuga3.jpg" className="w-100" alt="Rare Suga" />
                                                </div>
                                          </div>
                                    </div>
                                    <div className="mt_30">
                                          <video className="ras_video2 h-100" width={"100%"} autoPlay loop muted playsInline>
                                                <source src="https://d1cihhbb125h48.cloudfront.net/1736427239.mp4" type="video/mp4" />
                                          </video>
                                    </div>                                                                
                                    <div className="row mt_30 gap-md-0 gap-4 mb-4">
                                          <div className="col-md-6">
                                                <div>
                                                      <img src="/assets/image/label/rare_suga/raresuga4.jpg" className="w-100" alt="Rare Suga" />
                                                </div>
                                          </div>
                                          <div className="col-md-6">
                                                <div>
                                                      <img src="/assets/image/label/rare_suga/raresuga5.jpg" className="w-100" alt="Rare Suga" />
                                                </div>
                                          </div>
                                    </div>
                              </div>
                        </div>
                  </div>

                  <LetsTalk />

                  <Footer />
            </>
      );
}