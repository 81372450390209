import React from "react";
import Header from "../component/Header";
import Footer from "../component/Footer";
import { Helmet } from "react-helmet";

export default function OmnesBranding() {

    return (
        <>
            <Helmet>
                <title></title>
                <meta name="title" content="" />
                <meta name="description" content="" />
                <meta name="keywords" content="" />
                <link rel="canonical" href="" />
            </Helmet>

            <Header />

            <div className="page_bg bg_omnes_brand">
                <div className="header_padding">
                    {/* hero section */}
                    <div className="section_detail">
                        <div className="container-fluid p-0">
                            <div className="d-xl-flex">
                                <div className="product_space">
                                    <p className="product_name healing_brand omnes_brand">Omanes</p>
                                </div>
                                <div className="w-100 position-relative">
                                    <video width={"100%"} autoPlay loop muted playsInline>
                                        <source src="/assets/image/portfolios/abox/abox.mp4" type="video/mp4" />
                                    </video>
                                    <div className="brand_logo">
                                        <img src="/assets/image/portfolios/omnes_branding/logo.svg" className="banner_logo w-100" alt="Omnes Logo" loading="lazy" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* detail section */}
                <div className="section_space">
                    <div className="container">
                        <div className="div-block-40 flex-column">
                            <div className="row">
                                <div className="col-xxl-7 col-lg-8 col-md-10">
                                    <p className="product_name writing_mod healing_brand omnes_brand">Published in 2024</p>
                                    <h1 className="services_title text-white mb-0">Omnes <br /> <span className="omnes_brand_title">Strategy for Wellness. Stand Out on Shelves.</span></h1>
                                </div>
                                <div className="vr_border"></div>
                                <div className="col-12">
                                    <p className="services_pera text-white mb-3">We work for Nourysh, a leading health and wellness brand, to deliver a comprehensive branding solution. Nourysh, known for its range of nutritional supplements, needed a refreshed identity to better represent its commitment to health and well-being.</p>
                                    <p className="services_pera text-white mb-3">Nourysh faced challenges with an outdated brand image and inconsistent visual elements, which affected their market presence.</p>
                                    <p className="services_pera text-white">aBox Agency created a striking new logo and developed detailed brand guidelines, including every aspect that reflect Nourysh's focus on vitality. Our team also designed brand elements, professional stationery, and 3D product visualizations to enhance their product appeal.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Logo section */}
                <div className="section_space">
                    <div className="container">
                        <div className="div-block-40 flex-column">
                            <img src="/assets/image/portfolios/omnes_branding/omnes1.jpg" className="banner_logo w-100" alt="Omnes Logo" loading="lazy" />
                        </div>
                    </div>
                </div>

                {/* Product section */}
                <div className="section_space">
                    <div className="container">
                        <div className="div-block-40 flex-column">
                            <div>
                                <img src="/assets/image/portfolios/omnes_branding/omnes2.jpg" className="w-100" alt="Omnes Brand" loading="lazy" />
                            </div>
                        </div>
                    </div>
                </div>

                {/* content Section */}
                <div className="section_space">
                    <div className="container">
                        <div className="div-block-40 flex-column">
                            <div className="row justify-content-center">
                                <div className="col-xxl-6 col-xl-7 col-lg-8 col-md-10">
                                    <h2 className="brand_title omnes_brand_title">Visuals & Identity Drive Sales & Shelf Appeal</h2>
                                    <p className="services_pera text-white font_regular mb-0">After aBox Agency's branding overhaul, Nourysh saw a remarkable boost in brand awareness and sales. The new logo, cohesive guidelines, and striking 3D product visuals enhanced their market presence, resulting in a strong, unified brand that stands out in the health and wellness industry.</p>
                                </div>
                            </div>
                            <div className="strenth_bg strenth_my_80 mb-0 p-0 bg_transparent">
                                <div className="gaps gap_10 gaps_columns">
                                    <img src="/assets/image/portfolios/omnes_branding/omnes3.jpg" alt="Omnes Brand" className="w-100" loading="lazy" />
                                    <img src="/assets/image/portfolios/omnes_branding/omnes4.jpg" alt="Omnes Brand" className="w-100" loading="lazy" />
                                    <img src="/assets/image/portfolios/omnes_branding/omnes5.jpg" alt="Omnes Brand" className="w-100" loading="lazy" />
                                    <img src="/assets/image/portfolios/omnes_branding/omnes6.jpg" alt="Omnes Brand" className="w-100" loading="lazy" />
                                    <img src="/assets/image/portfolios/omnes_branding/omnes7.jpg" alt="Omnes Brand" className="w-100" loading="lazy" />
                                    <img src="/assets/image/portfolios/omnes_branding/omnes8.jpg" alt="Omnes Brand" className="w-100" loading="lazy" />
                                    <img src="/assets/image/portfolios/omnes_branding/omnes9.jpg" alt="Omnes Brand" className="w-100" loading="lazy" />                                    
                                    <img src="/assets/image/portfolios/omnes_branding/omnes10.jpg" alt="Omnes Brand" className="w-100" loading="lazy" />                                    
                                </div>
                            </div>
                            <div className="pb-5 mt_10">
                                <img src="/assets/image/portfolios/omnes_branding/omnes11.jpg" alt="Omnes Brand Brand" className="w-100" loading="lazy" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <Footer />
        </>
    );
}